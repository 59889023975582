var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c1f52d7ba7e07177c0b02755daebed4715cc1f34"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { Replay } from '@sentry/replay'

Sentry.init({
  dsn: 'https://780070ffa6da4652849c8d2a75693264@o962087.ingest.sentry.io/5917685',

  environment: process.env.APP_ENV,
  tracesSampleRate: process.env.APP_ENV === 'production' ? 0.2 : 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.APP_ENV === 'production' ? 0.2 : 0.5,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['tilla.test', 'tilla.tech'],
    }),
    new Replay({
      maskAllText: process.env.APP_ENV === 'production',
      blockAllMedia: process.env.APP_ENV === 'production',
    }),
  ],
})
